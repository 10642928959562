import { Global } from "@emotion/react";
import {
  ComponentsThemeProvider,
  embedEmotionCache,
  EmbedThemeProvider,
} from "@hl/base-components/lib/theme/provider";
import { useAccountSettings } from "@hl/shared-features/lib/features/auth/hooks/useAccountSettings";

import { globalStyles } from "../global-styles";

import { defaultTheme } from "./theme";

export const AppThemeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { enableDarkMode, userColorScheme, updateUserColorScheme } =
    useAccountSettings();
  return (
    <ComponentsThemeProvider
      legacyTheme={defaultTheme}
      enableDarkMode={enableDarkMode}
      userColorScheme={userColorScheme}
      updateUserColorScheme={updateUserColorScheme}
    >
      <Global styles={globalStyles} />
      {children}
    </ComponentsThemeProvider>
  );
};

export { embedEmotionCache, EmbedThemeProvider };
