import { memo, useEffect } from "react";

import { EXTERNAL_URL } from "../config";

const HomePage = () => {
  useEffect(() => {
    window.location.replace(EXTERNAL_URL.HIGHLIGHT);
  }, []);
  return null;
};

export default memo(HomePage);
