import { FC, lazy, memo, PropsWithChildren, Suspense } from "react";

import { useReactiveVar } from "@apollo/client";

import { modalVar, ModalType } from "../Modal/modal";

const GatedEntityAllPrereqsModal = lazy(
  () => import("./../../dashboard/GatedEntityAllPrereqsModal")
);

const Root: FC<PropsWithChildren> = ({ children }) => {
  const { showModal } = useReactiveVar(modalVar);

  return (
    <>
      {children}
      <Suspense fallback={""}>
        {showModal === ModalType.GATED_ENTITY_ALL_PREREQS && (
          <GatedEntityAllPrereqsModal />
        )}
      </Suspense>
    </>
  );
};

export default memo(Root);
