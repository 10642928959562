import { memo, ReactNode } from "react";

import { CloseX } from "@hl/communities-app/lib/assets/icons.generated";
import CardContainer from "@hl/communities-app/lib/components/CardContainer";
import Text from "@hl/communities-app/lib/components/Typography/Text";
import { AlertTemplateProps, AlertOptions } from "react-alert";

import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";
import { ReactComponent as SuccessIcon } from "../../assets/icons/success.svg";
import { ReactComponent as WarnIcon } from "../../assets/icons/warn.svg";

import * as styles from "./styles";

export type Warning = {
  warningMessage: ReactNode;
};

const Alert = ({ style, options, message, close }: AlertTemplateProps) => {
  const getWarning = (message: ReactNode) =>
    // really?
    (message as unknown as Warning).warningMessage;

  const getIcon = (isWarn: boolean, options: AlertOptions) => {
    if (isWarn) return <WarnIcon />;
    switch (options.type) {
      case "info":
        return <InfoIcon />;
      case "success":
        return <SuccessIcon />;
      case "error":
        return <ErrorIcon />;
    }
  };

  const warnMessage = getWarning(message);
  return (
    <div style={style}>
      <CardContainer extraCss={styles.card}>
        <div css={styles.message}>
          {getIcon(!!warnMessage, options)}
          <Text bold size="large" as="span">
            {warnMessage ? warnMessage : message}
          </Text>
        </div>
        <button onClick={close}>
          <CloseX css={styles.closeIcon} />
        </button>
      </CardContainer>
    </div>
  );
};

export default memo(Alert);
