import { useCallback, useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { useGetUserAccountSettingsLazyQuery, useSetColorSchemeMutation, } from "../queries/queries/accountSettings.graphql.generated";
import { accountSettingsVar, identityVar } from "../vars";
import { FEATURE_FLAGS, useFeatureFlags } from "./useFeatureFlags";
export const useAccountSettings = () => {
    const enableDarkMode = useFeatureFlags(FEATURE_FLAGS.SHOW_DARK_MODE_SETTINGS);
    const identity = useReactiveVar(identityVar);
    const [userColorScheme, setUserColorScheme] = useState();
    const [getUserAccountSettings] = useGetUserAccountSettingsLazyQuery({
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            const settings = data.getAccountSettings;
            accountSettingsVar({
                ...settings,
                loading: false,
            });
            setUserColorScheme(settings.colorScheme);
        },
    });
    useEffect(() => {
        if (!(identity === null || identity === void 0 ? void 0 : identity.account))
            return;
        getUserAccountSettings();
    }, [identity, getUserAccountSettings]);
    const [setColorSchemeMutation] = useSetColorSchemeMutation();
    const updateUserColorScheme = useCallback((newColorSchemeType) => {
        return setColorSchemeMutation({
            variables: {
                colorScheme: newColorSchemeType,
            },
        });
    }, [setColorSchemeMutation]);
    return {
        enableDarkMode,
        updateUserColorScheme,
        userColorScheme,
    };
};
