import { Suspense, useEffect } from "react";

import { loadIframely } from "@hl/communities-app/lib/services/iframely";
import reportWebVitals from "@hl/communities-app/lib/services/reportWebVitals";
import { AuthProvider } from "@hl/shared-features/lib/features/auth";
import { AppBootstrap } from "@hl/shared-features/lib/features/auth/AppBootstrap";
import { useBootstrap } from "@hl/shared-features/lib/features/auth/hooks";
import useGaTracker from "@hl/shared-features/lib/hooks/useGaTracker";
import { positions, Provider as AlertProvider } from "react-alert";
import { Metric } from "web-vitals";

import Alert from "./components/Alert";
import { GOOGLE_ANALYTICS_ID } from "./config";
import Root from "./features/layout/Root";
import { Routes } from "./routes";
import { AppThemeProvider } from "./styles/theme/provider";
import "./app.css";

export const App = () => {
  useBootstrap();

  const { isInitialized, emitGaEvent } = useGaTracker(GOOGLE_ANALYTICS_ID);

  useEffect(() => {
    loadIframely();
  }, []);

  useEffect(() => {
    if (isInitialized) {
      reportWebVitals((x: Metric) => {
        emitGaEvent(x);
      });
    }
  }, [isInitialized]);

  return (
    <AppThemeProvider>
      <Suspense fallback={""}>
        <AuthProvider>
          <AppBootstrap>
            <AlertProvider
              template={Alert}
              position={positions.TOP_CENTER}
              timeout={5000}
            >
              <Root>
                <Routes />
              </Root>
            </AlertProvider>
          </AppBootstrap>
        </AuthProvider>
      </Suspense>
    </AppThemeProvider>
  );
};
