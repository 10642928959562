import "./init";
import "@hl/shared-features/lib/features/logging/openTelemetry";
import { StrictMode } from "react";

import { ApolloProvider } from "@apollo/client";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import { App } from "./app";
import { apolloClient } from "./services/apolloClient";

TimeAgo.addDefaultLocale(en);
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <StrictMode>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </ApolloProvider>
  </StrictMode>
);
// .
