import { NotFound } from "@hl/shared-features/lib/features/layout";
import { Route, Routes as ReactRoutes } from "react-router-dom";

import HomePage from "./features/HomePage";
import { PageView } from "./features/pages/PageView";

export const Routes = () => {
  return (
    <ReactRoutes>
      <Route path="/" element={<HomePage />} />
      <Route path={"/page/:pageSlug"} element={<PageView />} />
      <Route path={"*"} element={<NotFound />} />
    </ReactRoutes>
  );
};
