import { css } from "@emotion/react";
import { topMargin } from "@hl/communities-app/lib/styles/global/layout";

export const card = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 24px;
  margin: 0;
  min-width: 400px;
  ${topMargin("4/8px")};
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
`;

export const message = css`
  display: flex;
  align-content: flex-start;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  margin: 0;
`;

export const closeIcon = css`
  width: 10px;
  height: 10px;
  fill: black;
  padding: 0;
`;
